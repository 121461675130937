
import {defineComponent} from "vue";
import {Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/core';
import '@splidejs/vue-splide/css';
import ProductCard from "./ProductCard.vue";
import gsap from "gsap";
import GetQuote from "@/components/product/GetQuote.vue";
import {mapGetters} from "vuex";

let defaultSize = 3;
let defaultMainTitle = "Default Title";

export default defineComponent({
  name: "SlideBar",
  components: {
    Splide,
    SplideSlide,
    ProductCard,
    GetQuote,
    SplideTrack
  },
  data() {
    return {
      addToFav: false,
      defaultSize,
      defaultMainTitle,
      isGetQuote: false,
      id: 0 as number,
    };
  },
  props: {
    items: Array,
    size: Number,
    mainTitle: String,
    currency: {
      type: String,
      required: false,
      default: "JOD",
    },
  },
  methods: {
    enter(el: any, done: any) {
      gsap.to(el, {
        duration: 0.8,
        opacity: 1,
        y: 0,
        onComplete: done,
        delay: el.dataset.index * 0.2,
      });
    },
    beforeEnter(el: any) {
      el.style.opacity = 0;
      el.style.transform = "translateY(100px)";
    },
    openModal(id: number) {
      this.isGetQuote = !this.isGetQuote;
      this.id = id;
    },
  },
  computed: {
    ...mapGetters({
      lang: 'localization/locale',
    }),
    options() {
      // If we use type:'loop' the product will be duplicated
      return {
        perPage:5,
        direction: this.lang === 'ar' ? 'rtl' : 'ltr',
        gap: '1rem',
        breakpoints: {
          1500: {
            perPage: 4
          },
            1200: {
                perPage: 3
            },
          768: {
            perPage: 2
          },
          576: {
            perPage: 2
          }
        }
      };
    }
  }
});
