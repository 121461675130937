import {createRouter, createWebHistory} from 'vue-router';
import routes from "./routes";
import store from "@/store";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: 'active-link',
    linkExactActiveClass: 'exact-active-link',
    routes
});

router.beforeEach((to, from, next) => {
    const locale = to.params.locale;
    const authenticated = store.getters['auth/isAuthenticated'];
    (document as any).title = to?.meta?.title || locale == 'en' ? 'Zalatimo Brothers' : 'الاخوين زلاطيمو';
    if (to.meta['title_' + locale]) {
        (document as any).title = to.meta['title_' + locale];
    }
    if (to.name === "Product Description") {
        console.log(to.name)
        const description = to.params.slug.replace(/-/g, ' ');

        document.title = (locale == 'en' ? "Zalatimo Brothers | " : 'الاخوين زلاطيمو | ') + description;
        console.log(description, 'title')
        // Set the dynamically generated description in the meta tag

        const metaTag = document.querySelector('meta[name="description"]');
        metaTag.setAttribute('content', description);
        // Assuming you're using Vue Meta for managing meta tags

    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (authenticated) {
            next();
        } else {
            store.dispatch('auth/toggleLoginModal', true);
            next(from.path);
        }
    } else {
        next();
    }
});
export default router;
