<template>
  <footer>
    <div class="row footer-up g-0 px-5">
      <div>
        <p class="text-center p-0 py-2 m-0">
          {{ $t("footer.oursocial") }}
        </p>
        <div>
          <ul class="footer-list p-0 d-flex justify-content-center g-0 gap-2">
            <li>
              <img
                  class="footer-icon"
                  alt="instagram"
                  src="@/assets/images/footer/insta.svg"
                  @click="pushMedia('instagram')"
              />
            </li>
            <li>
              <img
                  class="footer-icon"
                  alt="instagram"
                  src="@/assets/images/footer/tw.svg"
                  @click="pushMedia('twitter')"
              />
            </li>
            <li>
              <img
                  class="footer-icon"
                  alt="facebook"
                  src="@/assets/images/footer/fa.svg"
                  @click="pushMedia('facebook')"
              />
            </li>
            <li>
              <a target="_blank" href="https://vm.tiktok.com/ZSdYDaLPB/"
              ><img src="@/assets/images/footer/tik.svg"
                    alt="tiktok"
                    class="footer-icon"

              /></a>
            </li>
            <li>

              <a target="_blank"
                 href="https://www.tripadvisor.com/Restaurant_Review-g293986-d9791521-Reviews-Zalatimo_Brothers_for_Sweets-Amman_Amman_Governorate.html">
                <img src="@/assets/images/footer/sub.svg"
                     class="footer-icon"
                     alt="tripadvisor"
                /></a>
            </li>
            <li>
              <img
                  alt="youtube"
                  target="_blank"
                  class="footer-icon"
                  src="@/assets/images/footer/you.svg"
                  @click="pushMedia('youtube')"
              />
            </li>
            <li>
              <img
                  alt="snapchat"
                  class="footer-icon"
                  src="@/assets/images/footer/snap.svg"
                  @click="showImg(1)"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="row g-0">
        <div class="col-12 col-xl-2 col-lg-4 col-md-6">
          <div
              class="footer-img-container d-flex justify-content-lg-center justify-content-center"
          >
            <img
                @click="changeRoute('index')"
                class="logo-footer"
                src="@/assets/images/footer/logoFooter.svg"
                alt="zalatiom"
            />
          </div>
          <div class="mt-3">
            <p
                class="px-2 p-0 m-0 font-mobile text-center"
            >
              {{ $t("footer.securePayment") }}
            </p>
            <div
                class="footer-list d-flex justify-content-lg-center justify-content-center gap-2 p-2"
            >
              <div >
                <img
                    src="@/assets/images/footer/paypal-logo.svg"
                    width="40"
                    alt="paypal"
                    height="40"
                />
              </div>

              <div >
                <img
                    src="@/assets/images/footer/amex.svg"
                    width="40"
                    alt="amex"
                    height="40"
                />
              </div>
              <div >
                <img
                    src="@/assets/images/footer/visa.svg"
                    width="40"
                    alt="visa"
                    height="40"
                />
              </div>
              <div>
                <img
                    src="@/assets/images/footer/master.svg"
                    width="40"
                    alt="master-card"
                    height="40"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-2 col-lg-4 col-md-6">
          <div class="footer-list">
            <p class="list-title">{{ $t("footer.products") }}</p>
            <ul
                class="list"
                :class="locale == 'ar' ? 'arabic-list' : 'list-items'"
            >
              <li @click="changeRoute('all-products?categoryId=1')">
                {{ $t("category.mediterranean") }}
              </li>
              <li @click="changeRoute('all-products?categoryId=15')">
                {{ $t("category.cakePatisserie") }}
              </li>
              <li @click="changeRoute('all-products?categoryId=34')">
                {{ $t("category.chocolates") }}
              </li>
              <li @click="changeRoute('all-products?categoryId=53')">
                {{ $t("category.traditional") }}
              </li>
              <li @click="changeRoute('all-products?categoryId=45')">
                {{ $t("category.specialPacking") }}
              </li>
              <li @click="changeRoute('all-products?categoryId=63')">
                {{ $t("category.knafeh") }}
              </li>
            </ul>
          </div>
          <div class="footer-list">
            <p @click="changeRoute('catering-services')"
               class="list-title"
               style="cursor: pointer; margin-bottom: 10px">
              {{ $t("header.catering") }}
            </p>
            <p @click="changeRoute('all-products?categoryId=53')"
               class="list-title"
               style="cursor: pointer; margin-bottom: 10px">
              {{ $t("header.gourmet") }}
            </p>
          </div>
        </div>
        <div class="col-12 col-xl-2 col-lg-4 col-md-6">
          <div class="footer-list">
            <p class="list-title">{{ $t("footer.shop") }}</p>
            <ul
                class="list"
                :class="locale == 'ar' ? 'arabic-list' : 'list-items'"
            >
              <li
                  v-for="shop in shopList1"
                  :key="shop.id"
                  @click="changePath(shop.id)"
              >
                {{ shop.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-xl-2 col-lg-4 col-md-6">
          <div class="footer-list mt-4">
            <ul
                class="list"
                :class="locale === 'ar' ? 'arabic-list' : 'list-items'"
            >
              <li
                  v-for="shop in shopList2"
                  :key="shop.id"
                  @click="changePath(shop.id)"
              >
                {{ shop.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-xl-2 col-lg-4 col-md-6">
          <div class="footer-list">
            <p class="list-title">{{ $t("aboutUs.aboutUs") }}</p>
            <ul
                class="list"
                :class="locale == 'ar' ? 'arabic-list' : 'list-items'"
            >
              <li @click="changeRoute('about-us')">
                {{ $t("aboutUs.ourStory") }}
              </li>
              <li @click="changeRoute('Certificate')">
                {{ $t("footer.awardsAndCertificate") }}
              </li>
              <li @click="changeRoute('about-us')">
                {{ $t("aboutUs.artSweetMaking") }}
              </li>
            </ul>
          </div>
          <div class="footer-list">
            <p
                class="list-title"
                style="cursor: pointer; margin-bottom: 10px"
                @click="changeRoute('Contact')"
            >
              {{ $t("footer.contact") }}
            </p>
            <p
                class="list-title"
                style="cursor: pointer; margin-bottom: 10px"
                @click="changeRoute('corporate')"
            >
              {{ $t("corporateTitle") }}
            </p>
          </div>
        </div>
        <div class="col-12 col-xl-2 col-lg-4 col-md-6 mt-3 mt-lg-0">
          <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3384.7227595980867!2d35.89414871567637!3d31.968417181223753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151ca1a4e8f34a39%3A0x41108d719fd1f22!2zWmFsYXRpbW8gQnJvdGhlcnMgZm9yIFN3ZWV0cyBTaG1laXNhbmkgLSDYp9mE2KPYrtmI2YrZhiDYstmE2KfYt9mK2YXZiCDZhNmE2K3ZhNmI2YrYp9iqINin2YTYtNmF2YrYs9in2YbZig!5e0!3m2!1sen!2sjo!4v1648500331924!5m2!1sen!2sjo"
              class="map-frame rounded-1"
              height="200"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title="zalatimo map"
          ></iframe>
        </div>
      </div>
      <div class="d-flex justify-content-between footer-down pt-3 mt-3 mt-lg-0">
        <div class="col-md-4">
          <p class="center-mobile fn-12">
            <bdi>
              {{ $t("footer.right" ,{'date':thisYear}) }}
            </bdi>
          </p>
        </div>
        <div class="col-md-4 d-flex justify-content-end">
          <p
              class="center-mobile fn-12 pointer"
              @click="changeRoute('terms-and-conditions')"
          >
            {{ $t("footer.terms&conditions") }}
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center pb-4">
        <div>
          <small>
            {{ $t("footer.poweredBy") }}
          </small>
          <img src="@/assets/images/rhinosoft.svg" alt="rhinosoft" width="90" height="45">
        </div>
      </div>
    </div>
    <vue-easy-lightbox
        :visible="visible"
        :imgs="require('@/assets/images/footer/snapchat.jpeg')"
        index="1"
        @hide="handleHide"
    ></vue-easy-lightbox>
  </footer>
</template>

<script>
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import VueEasyLightbox from 'vue-easy-lightbox'


export default defineComponent({
  computed: {
    ...mapGetters({
      locale: "localization/locale",
    }),
    components: {VueEasyLightbox},
    shopList1() {
      return [
        {name: this.$t("footer.shops.Shmeisani"), id: 5},
        {name: this.$t("footer.shops.AlMadina"), id: 6},
        {name: this.$t("footer.shops.Swefieh"), id: 7},
        {name: this.$t("footer.shops.Abdulla"), id: 8},
        {name: this.$t("footer.shops.Dabouq"), id: 9},
        {name: this.$t("footer.shops.Abdali"), id: 2},
      ];
    },
    shopList2() {
      return [
        {name: this.$t("footer.shops.AirportRoad"), id: 10},
        {name: this.$t("footer.shops.AirportCentral"), id: 11},
        {name: this.$t("footer.shops.AirportDeparture"), id: 12},
        {name: this.$t("footer.shops.AirportNorth"), id: 13},
        {name: this.$t("footer.shops.AirportSouth"), id: 14},
        {name: this.$t("footer.shops.Dubai"), id: 15},

      ];
    },
    thisYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    changePath(id) {
      this.scroll();
      this.$router.push({
        name: "StoresItems",
        params: {id: id},
      });
    },
    scroll() {
      window.scroll({
        top: 0,
        left: 100,
        behavior: "smooth",
      });
    },
    changeRoute(name) {
      this.scroll();
      this.$router.push('/' + this.locale + '/' + name);
    },
    pushMedia(media) {
      if (media === "facebook") {
        window.open("https://www.facebook.com/zalatimogroup");
      }

      if (media === "instagram") {
        window.open("https://www.instagram.com/zalatimobrothersforsweets/");
      }

      if (media === "twitter") {
        window.open("https://twitter.com/ZalatimoBros");
      }

      if (media === "youtube") {
        window.open(
            "https://www.youtube.com/channel/UCifFheMeBg6oe5ex-Q-SvPw/videos"
        );
      }
    },
    showImg(index) {
      this.index = index
      this.visible = true
      console.log(this.visible)
    },
    handleHide() {
      this.visible = false
    }
  },
  data() {
    return {
      visible: false,
    }
  },
  components: {VueEasyLightbox},
});
</script>
<style scoped lang="scss">
@import "~@/assets/styles/helpers/include";

.footer-up {
  background-color: #fff;
  color: $secondary2;
  border-top: 1px solid $secondary;
}

.footer-list {
  list-style: none;

  img {
    cursor: pointer;
  }
}

.footer-down {
  border-top: 1px solid $secondary;
}

.footer {
  font-family: $footer-font-family;
  background-color: $primary;
  color: $footer-text-color;
  padding: 20px 45px 0 45px;
  @include md-max{
    padding: 20px 15px;
  }
  .follow {
    padding: 0px;
    margin: 0px;
    @include font($font-sm - 2);
  }

  .footer-list {
    margin-bottom: 18px;

    .list-title {
      padding: 0;
      margin: 0;
      color: $white;
      @include font($font-sm);
      font-weight: 700;
      @include md-max{
        width: 100%;
        display: block;
        text-align: start;
        font-size: 1.6rem;
      }
    }

    .list {
      list-style: none;
      cursor: pointer;
      padding: 0;
      margin: 0;

      li {
        margin-top: 7px;
        text-decoration: none;
        color: #fff !important;
        position: relative;
        width: fit-content;
        font-size: 12px;
      }
    }

    .list-items {
      li::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        right: 0;
        bottom: -2px;
        background: currentColor;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 250ms ease-in;
      }

      li {
        font-family: MyriadPro,serif;
        font-size: .8rem;
        @include md-max{
          font-size: 1.4rem;
        }
      }

      li:hover::after {
        transform: scaleX(1);
      }
    }

    .arabic-list {
      li::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: -2px;
        background: currentColor;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 250ms ease-in;
      }

      li:hover::after {
        transform: scaleX(1);
      }
    }
  }

  .map-frame {
    width: 100%;
  }
}

.footer-img-container {
  img {
    max-width: 170px;
    height: 140px;

    object-fit: cover;

    @include xl {
      max-width: 170px;
      height: 140px;
    }
  }
}

.icon {
  cursor: pointer;
  border-radius: 5px;
  background-repeat: no-repeat;
  height: 35px;
  width: 35px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hover {
  width: 35px;
  height: 35px;

  svg {
    transition: fill 0.3s ease-in-out;
    width: 36px;
    height: 36px;
  }

  transition: fill 0.3s ease-in-out;

  &:hover {
    svg {
      fill: #b1a05a;
    }

    g {
      fill: #fff;

      path {
        fill: #fff;
      }
    }

    path {
      fill: #b1a05a;
    }
  }
}

.hover-tick {
  width: 35px;
  height: 35px;

  svg {
    transition: fill 0.3s ease-in-out;
    width: 36px;
    height: 36px;
  }

  transition: fill 0.3s ease-in-out;

  &:hover {
    svg {
      fill: #b1a05a;

      path {
        &:last-child {
          fill: #fff;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .list-title {
    padding: 0;
    margin: 0;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
  }
  .list {
    list-style: none;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.footer-icon {
  width: 25px;
  height: 25px;
}

.logo-footer {
  cursor: pointer;
  width: 100%;
  height: auto;
  animation-name: logoAnimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes logoAnimation {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(0.85);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}


.footer-icon {
  cursor: pointer;

  &:hover {
    animation: icon .5s reverse;
  }
}

@keyframes icon {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}


.rhinoSoft{
  all: unset;
  cursor: pointer;
  color: $white;
  display: inline-block;
  position: relative;
  height: fit-content;
}

.rhinoSoft::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  right: 0;
  bottom: -2px;
  background: currentColor;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 250ms ease-in;
}


.rhinoSoft:hover::after {
  transform: scaleX(1);
}

.center-mobile{
  @include md-max{
    text-align: center;
  }
}

.font-mobile{
  padding: 0;
  margin: 0;
  color: $white;
  @include font($font-sm);
  font-weight: 700;
  line-height: 1;
  @include md-max{
    width: 100%;
    display: block;
    text-align: start;
    font-size: 1.4rem;
  }}

.fn-12{
  font-size: 12px;
}

.pointer{
  cursor: pointer;
}
</style>

