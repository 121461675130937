import {RouteRecordRaw} from "vue-router";
import store from "@/store";
import PassThrough from "@/components/PassThrough.vue";
import Home from "@/views/Home.vue";
import NotFound from "@/views/NotFound.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "lan",
        redirect: store.getters["localization/locale"],
        meta: {
            title: "Zalatimo Brothers",
            title_en: "Zalatimo Brothers",
            title_ar: "الاخوين زلاطيمو",
        }
    },
    {
        path: "/:locale",
        name: "Local",
        component: PassThrough,
        children: [
            {
                path: "",
                name: "HomePage",
                component: Home,
                meta: {
                    title: "Zalatimo Brothers | Home",
                    title_en: "Zalatimo Brothers | Home",
                    title_ar: " الاخوين زلاطيمو | الرئيسية",
                }
            },
            {
                path: "index",
                name: "Home",
                component: Home,
                meta: {
                    title: "Zalatimo Brothers | Home",
                    title_en: "Zalatimo Brothers | Home",
                    title_ar: " الاخوين زلاطيمو | الرئيسية",
                }
            },
            {
                path: "about-us",
                name: "AboutUs",
                component: () => import("@/views/about-us/AboutUs.vue"),
                meta: {
                    title: "Zalatimo Brothers | About Us",
                    title_en: "Zalatimo Brothers | Home",
                    title_ar: " الاخوين زلاطيمو | من نحن",
                }
            },
            {
                path: "our-history",
                name: "OurHistory",
                component: () => import("@/views/about-us/OurHistory.vue"),
                meta: {
                    title: "Zalatimo Brothers | Our History",
                    title_en: "Zalatimo Brothers | Our History",
                    title_ar: " الاخوين زلاطيمو | قصتنا",
                }
            },
            {
                path: "virtual-tour",
                name: "VirtualTour",
                component: () => import("@/views/about-us/VirtualTour.vue"),
                meta: {
                    title: "Zalatimo Brothers | Virtual Tour",
                    title_en: "Zalatimo Brothers | Virtual Tour",
                    title_ar: " الاخوين زلاطيمو | جولة افتراضية",
                }
            },
            {
                path: "catering-services",
                name: "CateringServices",
                component: () => import("@/views/CateringServices.vue"),
                meta: {
                    title: "Zalatimo Brothers | Catering Services",
                    title_en: "Zalatimo Brothers | Catering Services",
                    title_ar: " الاخوين زلاطيمو |خدمت الحفلات",
                }
            },
            {
                path: "product-description/:id/:slug",
                name: "Product Description",
                component: () => import("@/views/ProductDescription.vue"),
                meta: {
                    title: "Zalatimo Berothers | Product Description",
                }
            },
            {
                path: "cart",
                name: "Cart",
                component: () => import("@/views/Cart.vue"),
                meta: {
                    title: "Zalatimo Brothers | Cart",
                    title_en: "Zalatimo Brothers | Cart",
                    title_ar: "الاخوين زلاطيمو| السلة",

                }
            },
            {
                path: "all-products",
                name: "AllProducts",
                component: () => import("@/views/all-product/AllProducts.vue"),
                meta: {
                    title: "Zalatimo Brothers | All Products",
                    title_en: "Zalatimo Brothers |All Products",
                    title_ar: "الاخوين زلاطيمو | كل الاصناف",

                }
            },
            {
                path: "profile",
                name: "Profile",
                component: () => import("@/components/profile/Profile.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Zalatimo Brothers | Profile"
                },
                children: [
                    {
                        path: "",
                        name: "Profile",
                        redirect: "profile/info",
                        meta: {
                            title: "Zalatimo Brothers | Profile"
                        }
                    },
                    {
                        path: "orders",
                        name: "my orders",
                        component: () => import("@/components/profile/MyOrders.vue"),
                        meta: {
                            title: "Zalatimo Brothers | My Orders"
                        }
                    },
                    {
                        path: "orders/:id",
                        name: "view order",
                        component: () => import("@/components/profile/ViewOrder.vue"),
                        meta: {
                            title: "Zalatimo Brothers | View Order"
                        }
                    },
                    {
                        path: "info",
                        name: "My Info",
                        component: () => import("@/components/profile/MyAccount.vue"),
                        meta: {
                            title: "Zalatimo Brothers | My Info"
                        }
                    },
                    {
                        path: "my-address",
                        name: "My Address",
                        component: () => import("@/components/locations/AddressList.vue"),
                        meta: {
                            title: "Zalatimo Brothers | My Address"
                        }
                    },
                    {
                        path: "add-address",
                        name: "Add Address",
                        component: () => import("@/components/locations/AddNewAddress.vue"),
                        meta: {
                            title: "Zalatimo Brothers | Add Address"
                        }
                    },
                    {
                        path: "update-address",
                        name: "Update Address Address",
                        component: () => import("@/components/profile/UpdateAddress.vue"),
                        meta: {
                            title: "Zalatimo Brothers | Update Address"
                        }
                    },
                    {
                        path: "view-address",
                        name: "View Address",
                        component: () => import("@/components/profile/ViewAddress.vue"),
                        meta: {
                            title: "Zalatimo Brothers | View Address"
                        }
                    },
                    {
                        path: "change-password",
                        name: "Change Password",
                        component: () => import("@/components/profile/ResetPassword.vue"),
                        meta: {
                            title: "Zalatimo Brothers | Change Password"
                        }
                    },
                    {
                        path: "qr-code",
                        name: "QR Code",
                        component: () => import("@/components/profile/QRCode.vue"),
                    },
                    // Catch All Route
                    {
                        path: ":pathMatch(.*)",
                        name: "Not Found   ",
                        redirect: "/all-products",
                        meta: {
                            title: "Zalatimo Brothers | Not Found"
                        }
                    },
                ],
            },
            {
                path: "order-reviews",
                name: "OrdersReview",
                component: () => import("@/views/orders/OrdersReview.vue"),
                meta: {
                    title: "Zalatimo Brothers | Orders Review",
                }
            },
            {
                path: "wishlist",
                name: "Wishlist",
                component: () => import("@/components/wishList/WishList.vue"),
                meta: {
                    title: "Zalatimo Brothers | Wishlist",
                }
            },
            {
                path: "store",
                name: "Stores",
                component: () => import("@/components/stores/Stores.vue"),
                meta: {
                    title: "Zalatimo Brothers | Stores",
                    title_en: "Zalatimo Brothers | Stores",
                    title_ar: "الاخوين زلاطيمو | متاجرنا",

                },
                children: [
                    {
                        path: ":id",
                        name: "StoresItems",
                        component: () => import("@/components/stores/StoreItems.vue"),
                    },
                ],
            },

            {
                path: "stores",
                name: "OurStores",
                component: () => import("@/views/Stores.vue"),
                meta: {
                    title: "Zalatimo Brothers | Our Stores",
                    title_en: "Zalatimo Brothers | Stores",
                    title_ar: "الاخوين زلاطيمو | متاجرنا",
                }
            },
            {
                path: "checkout",
                name: "Checkout",
                component: () => import("@/views/Location.vue"),
                meta: {
                    title: "Zalatimo Brothers | Checkout",
                }
            },
            {
                path: "contact",
                name: "Contact",
                component: () => import("@/components/contact-us/ContactUs.vue"),
                meta: {
                    title: "Zalatimo Brothers | Contact",
                    title_en: "Zalatimo Brothers | Contact",
                    title_ar: "الاخوين زلاطيمو | اتصل بنا",
                }
            },
            {
                path: "terms-and-conditions",
                name: "Terms",
                component: () => import("@/views/conditions/term/Term.vue"),
                meta: {
                    title: "Zalatimo Brothers | Terms",
                }
            },
            {
                path: "certificate",
                name: "Certificate",
                component: () => import("@/views/certificate/View.vue"),
                meta: {
                    title: "Zalatimo Brothers | Certificate",
                }
            },
            {
                path: "order-confirmed",
                name: "order-confirmed",
                component: () => import("@/views/orders/ConfirmedOrders.vue"),
                meta: {
                    title: "Zalatimo Brothers | Order Confirmed",
                }
            },
            {
                path: "corporate",
                name: "Corporate",
                component: () => import("@/views/Corporate.vue"),
                meta: {
                    title: "Zalatimo Brothers | Corporate",
                }
            },
            {
                path: "/:locale/thanks",
                name: "ThanksPage",
                component: () => import("@/views/main-pages/Thanks.vue"),
                meta: {
                    title: "Zalatimo Brothers | Thanks",
                }
            },
            // Catch All Route
            {
                path: ":pathMatch(.*)",
                name: "NotFound",
                meta: {
                    title: "Zalatimo Brothers | Not Found",
                },
                component: NotFound,
            },
        ],
    },
];

export default routes;
