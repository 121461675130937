import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/popular/unlike.svg'
import _imports_1 from '@/assets/images/popular/liked.svg'


const _withScopeId = n => (_pushScopeId("data-v-bb579ebc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "new-card h-100 d-flex flex-column" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "h-100" }
const _hoisted_4 = { class: "new-card-body d-flex flex-column justify-content-between h-100" }
const _hoisted_5 = { class: "new-card-container" }
const _hoisted_6 = { class: "new-card-name" }
const _hoisted_7 = { class: "product-price text-uppercase" }
const _hoisted_8 = {
  class: "new-card-end",
  role: "button"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBSpinner = _resolveComponent("MDBSpinner")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_GetQuote = _resolveComponent("GetQuote")!
  const _component_MDBModal = _resolveComponent("MDBModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "new-card-image-container",
        role: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goTo && _ctx.goTo(...args)))
      }, [
        (_ctx.item?.mediaUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              loading: "lazy",
              class: _normalizeClass(_ctx.category ?'new-card-image-cover' : 'new-card-image' ),
              src: _ctx.item?.mediaUrl ? _ctx.item?.mediaUrl.replace('https://zalatimo.fra1.digitaloceanspaces.com/', 'https://zalatimocdn.exceptionsoft.com/') : require('@/assets/images/'+_ctx.$myGlobalVariable?.defaultImage),
              alt: "media"
            }, null, 10, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "new-card-start",
              role: "button",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goTo && _ctx.goTo(...args)))
            }, [
              _createElementVNode("p", _hoisted_6, [
                _createElementVNode("bdi", null, _toDisplayString(_ctx.local === "ar" && _ctx.item?.translations && _ctx.item?.translations?.arabic
                                    ? _ctx.item?.translations?.arabic?.name
                                    : _ctx.item?.name?.length > 30
                                        ? _ctx.item?.name?.slice(0, 30) + "..."
                                        : _ctx.item?.name), 1)
              ]),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.currency) + " " + _toDisplayString(_ctx.price), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              (!_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_Transition, { name: "fade" }, {
                      default: _withCtx(() => [
                        (!_ctx.fav)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              class: "product-icon",
                              alt: "not favorite",
                              src: _imports_0,
                              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addToFavourite && _ctx.addToFavourite(...args)))
                            }))
                          : (_openBlock(), _createElementBlock("img", {
                              key: 1,
                              class: "product-icon",
                              alt: "favorite",
                              src: _imports_1,
                              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.RemoveFromFav && _ctx.RemoveFromFav(...args)))
                            }))
                      ]),
                      _: 1
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_MDBSpinner, {
                      grow: "",
                      class: "primary",
                      size: "sm"
                    })
                  ]))
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            (_ctx.canAddToCart)
              ? (_openBlock(), _createBlock(_component_BaseButton, {
                  key: 0,
                  class: "w-100",
                  onClick: _ctx.addToCart
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("home.addtocart")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : (!_ctx.item.canBuy)
                ? (_openBlock(), _createBlock(_component_BaseButton, {
                    key: 1,
                    class: "w-100",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('open', _ctx.item.id)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("getQuote")), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_BaseButton, {
                    key: 2,
                    class: "w-100"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("home.outOfStock")), 1)
                    ]),
                    _: 1
                  }))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_MDBModal, {
        id: "GetQuote",
        tabindex: "-1",
        labelledby: "GetQuoteLabel",
        modelValue: _ctx.isGetQuote,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isGetQuote) = $event)),
        centered: "",
        size: "lg"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_GetQuote, {
            onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isGetQuote = false)),
            productId: _ctx.item?.id
          }, null, 8, ["productId"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}