
import {defineComponent} from "vue";
import {addToCartCard} from "@/services/cart.service";
import GetQuote from "@/components/product/GetQuote.vue";
import {ProductCard, ProductCategory, ProductVariation} from "@/interfaces/product-card";
import {mapGetters, mapActions} from "vuex";
import store from "@/store";

export default defineComponent({
    name: "ProductCard",
    emits: ["open"],
    props: {
        item: {
            type: Object as () => ProductCard,
            required: true,
        },
        favorite: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            active: false,
            favourite: false,
            isGetQuote: false,
            isLoading: false
        };
    },
    components: {GetQuote},
    methods: {
        ...mapActions({
            addToWishlist: "wishlist/addToWishlist",
            RemoveFromWishlist: "wishlist/RemoveFromWishlist",
            signUpGuest: "auth/signUpGuest",
        }),
        async addToCart() {
            await this.signUpGuest();
            await store.dispatch("notification/setNotification", {
                type: "success",
                message: this.$t("home.addedtocart"),
            });
            let data: any = await addToCartCard({
                productId: this.item.id,
                quantity: 1,
            });
            if (data.status === 201) {
                await store.dispatch("cart/getCartItems");
                await store.dispatch("notification/setNotification", {
                    type: "success",
                    message: null,
                });
            }
        },
        async addToFavourite() {
            await this.signUpGuest();
            this.isLoading = true;
            await this.addToWishlist({id: this.item.id});
            this.isLoading = false
            this.favourite = true;
        },
        async RemoveFromFav() {
            await this.signUpGuest();
            this.isLoading = true;
            await this.RemoveFromWishlist({id: this.item.id});
            this.isLoading = false
            this.favourite = false;
        },
        goTo() {


          var slug=this.item?.translations?.arabic?.name?.toLowerCase() // Convert to lowercase
             .replace(" ",'-')

              .trim();

          return this.$router.push({path: `/${this.local}/product-description/${this.item.id}/${this.local=='en'?this.item.slug:(slug??this.item.slug)}`});
        },
    },
    computed: {
        ...mapGetters({
            local: "localization/locale",
            currency: "localization/currency",
        }),
        fav() {
            return this.favourite;
        },
        category(): boolean {
            let ramadan = false;
            this?.item?.product?.productCategories?.forEach((item: ProductCategory) => {
                if (item?.categoryId === 62 || item?.category?.parentId === 62) {
                    ramadan = true;
                }
            });
            return ramadan;
        },
        price() {
            return this.item?.price?.toFixed(2);
        },
        path() {
            return `/${this.local}/product-description/${this.item?.id}/${this.item?.slug}`;
        },
        currency() {
            return localStorage.currency;
        },
        canAddToCart(): boolean {
            if (this.item?.product?.productVariations?.length === 0) return true
            const product = this.item?.product?.productVariations[0]
            return ((product?.inStock && product?.quantity > 0) || !product?.inStock) && this.item?.canBuy

        }
    },
    created() {
        this.favourite = this.item?.wishlist;
        if (this.favorite) {
            this.favourite = true;
        }
    },
});
